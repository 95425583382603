import React, { useRef } from "react";
import styled from "styled-components";

const SpotlightContainer = (props) => {
  if (!props.jobData) {
    return <p>Loading...</p>;
  }

  return (
    <Spotlight>
      <Experience>
        <Row>
          <Column>
            {props.jobData.jobName}
            <LightText>(Click Project to Spotlight)</LightText>
          </Column>
          <a>
            <RoundedButton
              href={props.jobData.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.jobData.button}
            </RoundedButton>
          </a>
        </Row>

        <SpotlightImage src={props.jobData.image} />

        <ListContainer>
          {props.jobData.stack.map((stack, idx) => (
            <ListItem key={idx}>
              {stack.image && <Image src={stack.image} alt={stack.name} />}
            </ListItem>
          ))}
        </ListContainer>

        <LightText>{props.jobData.description}</LightText>
      </Experience>
    </Spotlight>
  );
};

const Experience = styled.div`
  align-items: start;
  align-content: flex-start;
  flex-wrap: wrap;
  background: var(--Pure-White, #fff);
  background-color: var(--Pure-White, #fff);
  display: flex;
  padding: 0px 3px 0px 3px;
  font-weight: 700;
  font-size: 18px;
  color: #333333;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const RoundedButton = styled.a`
  background-color: var(--theme-color);
  color: white;
  padding: 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const LightText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  text-align: justify;
`;

const SpotlightImage = styled.img`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 354;
  margin-top: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Image = styled.img`
  margin-right: 16px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Text = styled.span`
  font-size: 12px;
  font-weight: 300;
  text-align: justify;
`;

const Spotlight = styled.div`
  max-width: 635px;
  --font-sans: "San Francisco", "Helvetica", "Arial", "Inter", -apple-system,
    system-ui, sans-serif;
  --font-mono: "San Francisco", "Helvetica", "Arial", "Inter", monospace;
  font-family: var(--font-sans);

  --theme-color: #0077b5;
`;

export default SpotlightContainer;
